import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './app/core/core.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideIonicAngular } from '@ionic/angular/standalone';

import { bootstrapApplication } from '@angular/platform-browser';
import { provideAuth } from './app/provideAuth';
import { provideRouting } from './app/provideRouting';
import { provideI18n } from './app/provideTransloco';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      IonicStorageModule.forRoot(),
      CoreModule.forRoot(), // TODO migrate to standalone
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ),
    provideIonicAngular(),
    provideHttpClient(withInterceptorsFromDi()), // wird für angular-oauth2-oidc, ngx-loading-bar benötigt
    provideLoadingBarInterceptor(), // Display the progress of your @angular/common/http requests.
    provideI18n(),
    provideRouting(),
    provideAuth()
  ]
})
  .catch(err => console.log(err));
