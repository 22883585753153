<ion-tabs *transloco="let t">

  <ion-tab-bar slot="bottom">

    <ion-tab-button tab="projects">
      <ion-icon name="calendar-outline" />
      <ion-label>{{ t('projects.title') }}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="hours">
      <ion-icon name="time-outline" />
      <ion-label>{{ t('hours.title') }}</ion-label>
    </ion-tab-button>

  </ion-tab-bar>

</ion-tabs>