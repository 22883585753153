import { Injectable, inject } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  private swUpdate = inject(SwUpdate);
  private toast = inject(ToastController);

  checkUpdate() {

    // https://angular.io/api/service-worker/SwUpdate#versionUpdates
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );

    updatesAvailable.subscribe(async () => {
      const toast = await this.toast.create({
        message: 'Update verfügbar!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Aktualisieren'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

}
