import { Route } from '@angular/router';

import { isLoggedInGuard, isAuthorizedGuard } from '@shared/auth';
import { TabsPage } from '@shell';

export const ROUTES: Route[] = [
  {
    path: '',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [isLoggedInGuard, isAuthorizedGuard],
    component: TabsPage,
    children: [
      {
        path: 'projects',
        children: [
          {
            path: '',
            loadChildren: () => import('./projects/project-list/project-list.routes'),
          },
        ]
      },
      {
        path: 'hours',
        children: [
          {
            path: '',
            loadChildren: () => import('./hours/hour-list/hour-list.routes'),
          },
        ],
      },
    ],
  },
  // Nicht in TabsPage und nicht unterhalb von Projects, damit Projekt-Details ohne Tabs angezeigt wird
  {
    path: 'projects/:projectNumber',
    canActivate: [isLoggedInGuard, isAuthorizedGuard],
    loadChildren: () => import('./projects/project/project.routes'),
  },
]