import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxLoadingBar } from '@ngx-loading-bar/core';
import {
  IonApp, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader,
  IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterOutlet, IonToggle
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendarOutline, timeOutline, personOutline, logOutOutline, moonOutline } from 'ionicons/icons';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthService, ProfileService } from '@shared/auth';
import { HourService } from './projects/hour.service';
import { GpsService, StorageService, ResumeService } from '@shared/services';

import { DeviceService, UpdateService } from '@shell';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonListHeader,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonToggle,
    NgxLoadingBar,
    TranslocoDirective,
    RouterLinkActive,
    RouterLink,
    DatePipe
  ]
})
export class AppComponent implements OnInit {
  private storage = inject(StorageService);
  private authService = inject(AuthService);
  private deviceService = inject(DeviceService);
  private resumeService = inject(ResumeService);
  private profileService = inject(ProfileService);
  private hourService = inject(HourService);
  private gps = inject(GpsService);
  private updateService = inject(UpdateService);

  appPages = [
    {
      title: 'projects.title',
      url: '/projects',
      icon: 'calendar-outline'
    },
    {
      title: 'hours.title',
      url: '/hours',
      icon: 'time-outline'
    },
  ];

  version;
  loggedIn = false;
  dark = false;
  resumed = null;
  userName;
  currentYear;
  hourCount = 0;

  constructor() {
    // needs to be called in the constructor, because before redirectTo-rule
    this.authService.runInitialLogin();
    this.version = environment.version;
    addIcons({ calendarOutline, timeOutline, personOutline, logOutOutline, moonOutline });
  }

  async ngOnInit() {
    this.updateService.checkUpdate();
    this.deviceService.setupDeviceId();
    this.resumeService.resumed$.subscribe(() => {
      this.hourService.send().subscribe();
      this.resumed = new Date();
    });
    this.hourService.hours$.subscribe(hours => {
      this.hourCount = hours.length;
    });
    var dark = await this.storage.get('dark');
    this.setDark(dark);
    this.currentYear = (new Date()).getFullYear();
    this.profileService.apiUrl$.pipe(filter(apiUrl => !!apiUrl)).subscribe(_ => {
      // Direkt nach start sobald ProfileService bzw. apiUrl ready ist
      this.hourService.send().subscribe();
    });

    this.authService.claims$.subscribe(claims => {
      if (claims) {
        this.userName = claims.name;
      }
    });


    this.gps.askPermission();
  }

  logout() {
    this.authService.logout();
  }

  setDark(dark: boolean) {
    this.dark = dark;
    this.storage.set('dark', dark);
    // Add or remove the "ion-palette-dark" class on the html element
    document.documentElement.classList.toggle('ion-palette-dark', dark);
  }

}
