import { Component } from '@angular/core';
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendarOutline, timeOutline } from 'ionicons/icons';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  templateUrl: 'tabs-page.html',
  imports: [
    TranslocoDirective,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
  ]
})
export class TabsPage {

  constructor() {
    addIcons({ calendarOutline, timeOutline });
  }

}