import { registerLocaleData } from '@angular/common';
import { NgModule, ModuleWithProviders, ErrorHandler, inject } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import localeDeCH from '@angular/common/locales/de-CH';

import { LogModule, LogInterceptor, LogErrorHandler } from '@viag/ngx-logger';

import { environment } from '../../environments/environment';

// CoreModule with providers for the singleton services you load when the application starts.
// Do gather application-wide, single use components (e.g. NavComponent and SpinnerComponent) in the CoreModule.
// Import it once (in the AppModule) when the app starts and never import it anywhere else.
@NgModule({
  imports: [
    LogModule.forRoot({
      application: environment.application,
      version: environment.version,
      logUrl: environment.logUrl,
      enableConsoleLog: environment.enableConsoleLog,
      enableRemoteLog: environment.enableRemoteLog,
      logLevels: {
        signalR: environment.production ? 3 : 2,
      },
    }),
  ],
  declarations: [
  ]
})
export class CoreModule {

  constructor() {
    const parentModule = inject(CoreModule, { optional: true, skipSelf: true });

    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
    registerLocaleData(localeDeCH);
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: ErrorHandler, useClass: LogErrorHandler },
      ]
    };
  }


}
