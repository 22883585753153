import { Injectable, inject } from '@angular/core';
import { StorageService } from '../../shared/services/storage.service';
import { RemoteLogService } from '@viag/ngx-logger';

import { Guid } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private storage = inject(StorageService);
  private remoteLogService = inject(RemoteLogService);

  setupDeviceId() {
    return this.storage.get('deviceId').then(deviceId => {
      if (!deviceId) {
        deviceId = Guid.uuidv4();
        this.storage.set('deviceId', deviceId);
      }
      this.remoteLogService.enrich({ deviceId });
      return deviceId;
    });
  }

}
