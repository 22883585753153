import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { AuthConfig, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';

import { environment } from "../environments/environment";

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

const authConfig: AuthConfig = {
  issuer: environment.stsUrl, // Url of the Identity Provider
  clientId: environment.clientId, // The SPA's id. The SPA is registerd with this id at the auth-server
  redirectUri: window.location.origin, // + '/index.html', // URL of the SPA to redirect the user to after login
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  responseType: 'code',
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  scope: 'openid profile employeeId apiUrl projects',
};

export function provideAuth(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideHttpClient(withInterceptorsFromDi()),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [], // dynamic value: see ProfileService
        sendAccessToken: true,
      },
    }),
    // TODO
    // provideAppInitializer
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
  ]);

}
