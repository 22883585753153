import { HttpClient } from '@angular/common/http';
import { EnvironmentProviders, Injectable, inject, isDevMode, makeEnvironmentProviders } from '@angular/core';
import {
  Translation,
  TranslocoLoader,
  provideTransloco,
  translocoConfig,
} from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export function provideI18n(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['de', 'fr'],
        defaultLang: navigator.language.substring(0, 2) || 'de',
        fallbackLang: 'de', // falls Sprache oder auch einzelner Key nicht gefunden wird
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      }),
      loader: TranslocoHttpLoader,
    })
  ]);

}